import './Nav.css';
import logo from '../../images/logo.jpeg';
import petjeaf from '../../images/petjeaf.png';

import { NavLink } from 'react-router-dom';

import ReactGA from 'react-ga4';

const trackGaEvent = (event) => {
    ReactGA.event({
        action: `Klik op ${event} link`,
        category: 'Externe links'
    });
};

function Nav() {
    return (
        <nav className="nav-zlt">
            <div className="nav-zlt--logo">
                <NavLink to={'/'} className="nav-zlt--logo">
                    <img alt="ZLT logo" src={logo} height="54" width="54" />
                    <span>Zes Losse Tanden DB</span>
                </NavLink>
            </div>
            <div className="nav-zlt--items">
                <div>
                    <NavLink to={'/'}>Afleveringen</NavLink>
                    <NavLink to={'/search'}>Zoeken</NavLink>
                    <NavLink to={'/fun-stats'}>Stats</NavLink>
                    <NavLink to={'/over'}>Over</NavLink>
                </div>
                <a className="nav-zlt--button petjeaf--button reverse" onClick={() => trackGaEvent('Petje Af')} href="https://petjeaf.com/zeslossetanden"><img alt="Podcast steunen via petje.af" src={petjeaf} /></a>
                <a className="nav-zlt--button" onClick={() => trackGaEvent('Podcast')} href="https://podlink.to/zeslossetanden">Luisteren</a>
            </div>
        </nav>
    )
}

export default Nav;
