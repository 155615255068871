import './TypeFilter.css';
import { NavLink } from 'react-router-dom';

export default function TypeFilter({ episodeTypes, setSelectedType, selectedType, currentYear }) {
    return (
        <div className="filter--type">
            <button className={selectedType === false ? "active" : ""} onClick={() => setSelectedType(false)}>Alles</button>
            {episodeTypes.map((type, index) => (
                <button className={selectedType === type ? "active" : ""} key={index} onClick={() => setSelectedType(type)}>{type === "ZLT" ? "Regulier" : type}</button>
            ))}
            {currentYear === "2023" ? <NavLink className="nav-zlt--button petjeaf--button reverse-pink" to={'/annual-best'}><span>✨Jaarlijst✨</span></NavLink> : ""}
        </div>
    )
}